import { Locale as DFNSLocale } from "date-fns";
import { enUS, es, kk, ptBR, ru } from "date-fns/locale";
import {
  ruRU as muiRuRU,
  enUS as muiEnUS,
  esES as muiEsES,
  ptBR as muiPtBR,
  kkKZ as muiKkKz,
  Localization,
} from "@mui/material/locale";
import { arrayToIdIndex } from "src/shared/arrayToIdIndex";

export const mapLangToDateFnsLocale: Record<string, DFNSLocale> = {
  "en": enUS,
  "pt-BR": ptBR,
  "pt-br": ptBR,
  ru,
  es,
  kk,
};

export const mapLangToMuiLocale: Record<string, Localization> = {
  "ru": muiRuRU,
  "en": muiEnUS,
  "es": muiEsES,
  "pt-BR": muiPtBR,
  "pt-br": muiPtBR,
  "kk": muiKkKz,
};

export const mapDomainToLanguage: Record<string, string> = {
  "ru": "ru",
  "local": "ru",
  "com": "en",
  "io": "pt-br",
  "kz": "kk",
};

export const langs = [
  {
    text: "Русский",
    value: "ru",
  },
  {
    text: "English",
    value: "en",
  },
  {
    text: "Español",
    value: "es",
  },
  {
    text: "Português (Brasileiro)",
    value: "pt-br",
  },
  {
    text: "Қазақ",
    value: "kk",
  },
] as const;

export const availableLanguages = arrayToIdIndex(langs, "value");

export const DEFAULT_LANGUAGE = "ru";

export const I18N_DEFAULT_WORKSPACE = "workspace";

export const I18N_EVENT = "change";

export enum I18n_namespace {
  "workspace" = "workspace",
  "auth" = "auth",
  "clients" = "clients",
  "companies" = "companies",
  "chat" = "chat",
  "ticket" = "ticket",
  "menu" = "menu",
  "editor" = "editor",
}
