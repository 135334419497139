import LanguageDetector from "i18next-browser-languagedetector";
import EventEmitter from "src/shared/EventEmitter";
import { availableLanguages, DEFAULT_LANGUAGE, I18N_EVENT, mapDomainToLanguage } from "./constants";

const languageDetector = new LanguageDetector({
  languageUtils: { getBestMatchFromCodes: true },
}, {
  lookupQuerystring: "lang",
  order: ["backend", "localStorage", "cookie", "querystring", "domain", "navigator", "htmlTag"],
  caches: ["localStorage", "cookie"],
});
languageDetector.addDetector({
  name: "domain",
  lookup() {
    const domainZone = new URL(window.location.href).hostname.split(".").slice(-1)[0];
    return mapDomainToLanguage[domainZone] || undefined;
  },
});
languageDetector.addDetector({
  name: "backend",
  lookup() {
    return window.AGENT?.lang || undefined;
  },
});

const emitter = new EventEmitter();

export const updateLanguage = (newLng: string) => {
  if (currentLanguage === newLng || !availableLanguages[newLng as keyof typeof availableLanguages]) return;
  currentLanguage = newLng.toLowerCase();
  languageDetector.cacheUserLanguage(currentLanguage);
  emitter.emit(I18N_EVENT, { lng: currentLanguage });
};

export const getCurrentLanguage = () => currentLanguage;

export const onChangeLanguage = (cb: (data: { lng: string }) => any) => emitter.on(I18N_EVENT, cb);

(window as any).updateLanguage = updateLanguage;

const checkLanguage = (languages: string[]) => {
  for (let i = 0; i < languages.length; i++) {
    const language = languages[i].toLowerCase();
    if (availableLanguages[language as keyof typeof availableLanguages]) return language;
  }
  return DEFAULT_LANGUAGE;
};

let currentLanguage = checkLanguage(languageDetector.detect() as string[]);
